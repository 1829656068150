import React, {useState} from 'react'
import './../../scss/navbarPopaps.scss'
import LoginArrow from './../../assets/images/loginArrow.png'
import onClickOutside from 'react-onclickoutside';


function BusinessE(){
    const [BusinessPopapOpen, setBusinessPopapOpen] = useState(true)

    BusinessE.handleClickOutside = () => setBusinessPopapOpen(true);
    function popapOpen(){
        setBusinessPopapOpen(false)
    }




    return(
        <div className='businesPopap menu-blok__margin'>
            <div  className='businesPopap__block' onClick={popapOpen}>
                <p className='businesPopap'>All Services</p>
                <img src={LoginArrow}  className='businesPopap__img' alt='arrow'/> 
            </div>
            <div className={BusinessPopapOpen ? 'businesPopap__Block' : 'businesPopap__Block-active'}>
                <p className='businesPopap__item'> Dba/fictious business name</p>
                <p className='businesPopap__item'>Ein/tax id number</p>
                <p className='businesPopap__item'>501c3 status</p>
                {/* <p className='businesPopap__item'>State Filing</p>
                <p className='businesPopap__item'>Name change amendment</p>
                <p className='businesPopap__item'>Address change amendment</p>
                <p className='businesPopap__item'>Member change amendment</p>
                <p className='businesPopap__item'>Foreign entity registration</p>
                <p className='businesPopap__item'>DBA/Fictitious business name</p> */}
            </div>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () =>
    BusinessE.handleClickOutside,
};
  
      
export default  onClickOutside(BusinessE, clickOutsideConfig);
