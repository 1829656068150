import React from 'react'
import Footer from '../components/Footer'
import Frequently from '../components/Frequently'
import IntroToC from '../components/introToC'
import SCopeForm from '../components/SCopeForm'
import Packages from '../components/packeges'
import GetStarted from '../components/getStarted'
import { Link } from 'react-scroll';
import './../scss/S_Cope.scss'

function Ein(){
    return(
        <div className='SCope'>
            <div className='baner'>
                <div className='baner__content'>
                    <h2 className='baner__content__title'>Ready to form your EIN?</h2>
                    <h3 className='baner__content__subtitle'>Three bullet points:</h3>
                    <p  className='baner__content_text'>Used to recognize your business by the IRS</p>
                    <p  className='baner__content_text'>Used to tax your income</p>
                    <p  className='baner__content_text'>Used to obtain business loans, open business account</p>
                    <div  className='baner__content__block'>
                        <GetStarted/>
                        <Link  className='baner__content__read'  to='packeges' smooth={true} duration={1000} offset={-150}>Read more below</Link>
                    </div>
                </div>
                <div id='packeges'/>
            </div>
            <Packages/>
            <IntroToC/>
            <Frequently/>
            <SCopeForm/>
            <Footer/>
        </div>
    )
}

export default Ein