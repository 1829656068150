import React from 'react'
import './../scss/corpVS.scss'
import g1 from './../assets/images/12.png'

function CorpVS(){
    return(
        <div className='CorpVS'>
            <h2  className='CorpVS__title__Text'>C-CORP</h2>
            <span  className='title__line'/>
            <div className='CorpVS__title'>
                <h2 className='CorpVS__titleAdv'><img src={g1} className='vsIcon' alt='good'/>Advantages</h2>
                <h2 className='CorpVS__titleVs'>VS</h2>
                <h2 className='CorpVS__titleDis'><img src={g1} className='vsIcon' alt='bad'/>Disadvantages</h2>
            </div>
            <div  className='CorpVS__block'>
                <h2 className='CorpVS__titleAdv CorpVS__title-mobile'>Advantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Limited Liability</h3>
                        <p  className='CorpVS__block__description'>Much like S-Corps and LLC’s, C-Corps offer liability protection for their owners. If legal action is taken against the corporation, the owners’ personal assets are protected.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Have infinite shareholders</h3>
                        <p  className='CorpVS__block__description'>As a C-Corp, you can bring on as many investors as you’d like. In fact, the companies you see on the New York Stock Exchange or NASDAQ have C-Corp status for that reason.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Unlimited lifespan</h3>
                        <p  className='CorpVS__block__description'>Similar to the S-Corp, if a member decides to leave the company, the company remains. Transfer of ownership is easy and manageable.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Easy to bring on new investors</h3>
                        <p  className='CorpVS__block__description'>C-Corps do not have the same restrictions as S-Corps do when it comes to bringing on new investors. You can have as many investors as you’d like, they can be from anywhere around the world, it’s not limited to only individuals, and you have more than one class of stock to offer.</p>
                    </div>
                </div>
                <h2 className='CorpVS__titleVs CorpVS__title-mobile '>VS</h2>
                <h2 className='CorpVS__titleDis CorpVS__title-mobile'>Disadvantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Complexity/formalities</h3>
                        <p  className='CorpVS__block__description'>There are complex tax rules for C-corps. There’s also great protection for owners from debts and liability, therefore the corporation must report annually to the state to prove they are adhering to certain formalities. Some of the formalities include issuing stock and holding regular board and shareholder meetings.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Double Taxation</h3>
                        <p  className='CorpVS__block__description'>The C-corp is the only entity with double taxation. When the company brings in profits, it is taxed on those profits. Then, when it pays its shareholders those profits(also known as dividends), the shareholders are taxed on the profits.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorpVS