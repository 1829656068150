import React from 'react'
import './../scss/incorporate.scss'
import IncorporateImg from './../assets/images/Incorporate.png'
import { Link } from 'react-router-dom';
import { ReactComponent as Path } from './../assets/images/incorporate.svg';


function Incorporate(){
    return(
        <div  className='incorporate__Box'>
            <Path className='Path'/>
            <div className='incorporate'>
                <img src={IncorporateImg} className='incorporateImg' alt='IncorporateImg'/>
                <div className='incorporate__Container'>
                    <Link className='webBTN'>Incorporate Now</Link>
                    <Link className='Learn_More__link'>{'Learn More >>'}</Link>
                </div>
            </div>
        </div>
    )
}

export default  Incorporate