import React, {useState} from 'react'
import './../../scss/navbarPopaps.scss'
import LoginArrow from './../../assets/images/loginArrow.png'
import onClickOutside from 'react-onclickoutside';
import { Link } from 'react-router-dom';

function BusinessE(){
    const [BusinessPopapOpen, setBusinessPopapOpen] = useState(true)

    BusinessE.handleClickOutside = () => setBusinessPopapOpen(true);
    function popapOpen(){
        setBusinessPopapOpen(BusinessPopapOpen => !BusinessPopapOpen)
    }




    return(
        <div className='businesPopap'>
            <div  className='businesPopap__block' onClick={popapOpen}>
                <p className='businesPopap'>Business Entities</p>
                <img src={LoginArrow}  className='businesPopap__img' alt='arrow'/> 
            </div>
            <div className={BusinessPopapOpen ? 'businesPopap__Block' : 'businesPopap__Block-active'} >
                <Link to='LLC_corp' className='businesPopap__item'  onClick={popapOpen}>LLC</Link>
                <Link to='/s_corp' className='businesPopap__item'  onClick={popapOpen}>s-corp</Link>
                <Link to='/c_corp' className='businesPopap__item'  onClick={popapOpen}>c-corp</Link>
                <Link to='/no_Profit' className='businesPopap__item'  onClick={popapOpen}>Non-profit</Link>
            </div>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () =>
    BusinessE.handleClickOutside,
};
  
      
export default  onClickOutside(BusinessE, clickOutsideConfig);
