import React, {useState} from 'react'
import './../scss/S_CopeForm.scss'
import S_formImg from './../assets/images/Incorporate.png'

function S_CopeForm(){


    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [newBusinesName, setNewBusinesName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")


    const [AddressStates, setAddressStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [selectAddressStates, setSelectAddressStates] = useState("")
    const AddressoptionsStates = AddressStates.map(AddressoptionsStates => AddressoptionsStates)
    function AddressChange(e){
        setSelectAddressStates(AddressStates[e.target.value]);
    }

    function S_form(event){
        event.preventDefault(); 
        alert("Name: " + name) 
        alert("lastName: " + lastName) 
        alert("newBusinesName: " + newBusinesName) 
        alert("state: "+  selectAddressStates)
        alert("email: "+ email)
        alert("phon: " + phone)
    }

    return(
        <div className='S_CopeForm'>
            <h2 className='title__Text'>If you have question ? Want a Free</h2>
            <h2 className='title__Text'> name search ? We'll contact you !</h2>
            <span className='title__line'></span>
            <div  className='S_CopeForm__container'>
                <form className='S_CopeForm_block' onSubmit={S_form}>
                    <label className='S_CopeForm__inp__container'>
                        <span className='visually-hidden'>First Name</span>
                        <input  className='S_CopeForm__inp' type='text' placeholder='First Name' onChange={e => setName(e.target.value)} value={name} />
                    </label>
                    <label className='S_CopeForm__inp__container'>
                        <span  className='visually-hidden'>Last Name</span>
                        <input  className='S_CopeForm__inp' type='text' placeholder='Last Name'  onChange={e => setLastName(e.target.value)} value={lastName} />
                    </label>
                    <label className='S_CopeForm__inp__container'>
                        <span  className='visually-hidden'>New Business Name</span>
                        <input  className='S_CopeForm__inp' type='text' placeholder='New Business Name'  onChange={e => setNewBusinesName(e.target.value)} value={newBusinesName} />
                    </label>
                    <label className='S_CopeForm__inp__container'>
                        <span  className='visually-hidden'>New Business Name</span>
                        <select  className='S_CopeForm__inp' onChange={AddressChange}> 
                            {
                                AddressoptionsStates.map((address, key) => <option value={key} key={key}>{address}</option>)
                            }
    	                </select>
                    </label>
                    <label className='S_CopeForm__inp__container'>
                        <span className='visually-hidden'>Email</span>
                        <input  className='S_CopeForm__inp' type='email'  placeholder='Email'  onChange={e => setEmail(e.target.value)} value={email} />
                    </label>
                    <label className='S_CopeForm__inp__container'>
                        <span className='visually-hidden'>Phone</span>
                        <input  className='S_CopeForm__inp' type='number' placeholder='Phone'  onChange={e => setPhone(e.target.value)} value={phone} />
                    </label>
                    <button className='webBTN' type='submit'>Submit</button>
                </form>
                <img src={S_formImg} className='S_formImg' alt='formImg'/>
            </div>
        </div>
    )
}

export default S_CopeForm