import React, {useState} from 'react'
import './../scss/loginModal.scss'

function LoginModal({LoginOpenActiv, loginOpen,  RegistrationOpen}){


    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")


    function login(event){
        // alert('Login')
        loginOpen()
        event.preventDefault(); 
    }

    return(
        <div className={LoginOpenActiv ? 'LoginModal' : "LoginModal__none"} onClick={loginOpen} >
            <form className='loginForm' onClick={(e) => { e.stopPropagation();}} onSubmit={login}>
                <div className='loginForm__container'>
                    <p  className='login__title'>Sign In to Fast<span  className='login__title__greenWord'>Track</span></p>
                    <label>
                        <span className='visually-hidden'>Mail/Username</span>
                        <input  onChange={e => setEmail(e.target.value)} value={Email} className='login__inp' type='text' placeholder='Mail/Username'  required/>
                    </label>
                    <label>
                        <span className='visually-hidden'>Password</span>
                        <input  onChange={e => setPassword(e.target.value)} value={Password} className='login__inp' type='password' placeholder='Password' required/>
                    </label>
                    <button  className='login__BTN' type='submit'>Sign In</button>
                </div>
                <div  className='loginForm__join'>
                    <p  className='loginForm__join__text'>Not a member yet?<span className='login__title__greenWord' onClick={RegistrationOpen} style={{cursor:"pointer"}}> Join now</span></p>
                </div>
            </form>
        </div>
    )
}

export default LoginModal