import React, {useState} from 'react'
import './../../scss/navbarPopaps.scss'
import LoginArrow from './../../assets/images/loginArrow.png'
import onClickOutside from 'react-onclickoutside';


function BusinessE(){
    const [BusinessPopapOpen, setBusinessPopapOpen] = useState(true)

    BusinessE.handleClickOutside = () => setBusinessPopapOpen(true);
    function popapOpen(){
        setBusinessPopapOpen(false)
    }




    return(
        <div className='businesPopap menu-blok__margin'>
            <div  className='businesPopap__block' onClick={popapOpen}>
                <p className='businesPopap'>FAQ</p>
                <img src={LoginArrow}  className='businesPopap__img' alt='arrow'/> 
            </div>
            <div className={BusinessPopapOpen ? 'businesPopap__Block' : 'businesPopap__Block-active'}>
                <p className='businesPopap__item'>Text Text Text</p>
                <p className='businesPopap__item'>Text Text Text</p>
                <p className='businesPopap__item'>Text Text Text</p>

            </div>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () =>
    BusinessE.handleClickOutside,
};
  
      
export default  onClickOutside(BusinessE, clickOutsideConfig);
