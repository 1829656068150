import React from 'react'
import './../scss/corpVS.scss'
import g1 from './../assets/images/12.png'

function CorpVS(){
    return(
        <div className='CorpVS'>
            <h2  className='CorpVS__title__Text'>LLC</h2>
            <span  className='title__line'/>
            <div className='CorpVS__title'>
                <h2 className='CorpVS__titleAdv'><img src={g1} className='vsIcon' alt='good'/>Advantages</h2>
                <h2 className='CorpVS__titleVs'>VS</h2>
                <h2 className='CorpVS__titleDis'><img src={g1} className='vsIcon' alt='bad'/>Disadvantages</h2>
            </div>
            <div  className='CorpVS__block'>
                <h2 className='CorpVS__titleAdv CorpVS__title-mobile'>Advantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Loss deductions</h3>
                        <p  className='CorpVS__block__description'>Members are able to deduct operating losses of the LLC from their personal income. Members of an S-Corp also have this option.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Tax flexibility</h3>
                        <p  className='CorpVS__block__description'>You can choose how the LLC is taxed. You can choose to be taxed as an S-corp or even C-Corp. These elections come with their respective advantages. Read more on the S-Corp and C-Corp pages!</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Fewer corporate formalities</h3>
                        <p  className='CorpVS__block__description'>Unlike Corporations, an LLC has much flexibility and very little formalities. Member meetings, extensive IRS paperwork, and more that you are not subject to as the owner of an LLC.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Fewer corporate formalities</h3>
                        <p  className='CorpVS__block__description'>There can be many owners for an LLC or just one. Ownership isn’t restricted to only individuals, other legal entities can be part or full owners as well.</p>
                    </div>
                </div>
                <h2 className='CorpVS__titleVs CorpVS__title-mobile '>VS</h2>
                <h2 className='CorpVS__titleDis CorpVS__title-mobile'>Disadvantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Loss deductions</h3>
                        <p  className='CorpVS__block__description'>LLC owners are subject to a 15.3% self-employment tax on all of the profits. This is different from S-corps who are able to keep profits with the company, and only take a salary which would be taxed at that same rate.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Member Turn Over</h3>
                        <p  className='CorpVS__block__description'>The company essentially dies when the owner, or one of the owners, leaves the business. This however does not apply to S-Corps or C-Corps.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Fringe benefits are treated as taxable income</h3>
                        <p  className='CorpVS__block__description'>Medical insurance/Group insurance is recognized as taxable income. Same goes for S-Corps, however employees of C-Corps do not report these benefits as taxable income.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Owners immediately recognize profits</h3>
                        <p  className='CorpVS__block__description'>With corporations, the money goes to the company FIRST and then owners pay themselves a salary in the form of dividends. With the LLC, the profits come direct to the owners. Money can not be off sided for lesser tax</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorpVS