import React from 'react'
import './../scss/popularServices.scss'
import ServicesSlider from './servicesSlider'



function Services(){
    return(
        <div className='services'>
            <h2  className='title__Text'>MOST POPULAR PACKAGES</h2>
            <span  className='title__line'/>
            <div className='services__slider'>
                <ServicesSlider/>
            </div>
        </div>
    )
}

export default Services