import React, {useState} from 'react'
import onClickOutside from 'react-onclickoutside';
import './../scss/navbar.scss';
import search from './../assets/images/search.png'

function SearchInput(){
    const [isSearchOpen, setIsSearchOpen] = useState(true)
    const [isSearchValue, setIsSearchValue] = useState("")

    SearchInput.handleClickOutside = () => setIsSearchOpen(true);
    function searchData(event){
        event.preventDefault(); 
        if(isSearchValue.length === 1){
            alert(isSearchValue)
        }
    }
    function serchOpen(){
        if(window.innerWidth <= 650){
            setIsSearchOpen(isSearchOpen => !isSearchOpen)
            console.log(window.innerWidth) 
        }
        else{
            setIsSearchOpen(false)
        }
    }

    return(
        <>
            <form className={isSearchOpen ? 'searchInp__Container' : 'searchInp__Container__mobile'} onSubmit={searchData}>
                <label className='searchInp__container'>
                    <span className='visually-hidden'>searchInp</span>
                    <input className='searchInp' type='text' onChange={e => setIsSearchValue(e.target.value)} value={isSearchValue}/>
                </label>
                <button className='searchInpBtn' type='submite'>Search</button>
            </form>
            <button className='searchBtn' onClick={serchOpen}><img src={search} alt='searchIcon' className='searchIcon'/></button>
        </>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () =>
    SearchInput.handleClickOutside,
};
  
export default onClickOutside(SearchInput , clickOutsideConfig);