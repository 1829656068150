import React, {useEffect, useRef, useState} from 'react'
import Footer from '../components/Footer'
import PackegesR from '../components/packegesR'
import './../scss/businessRegistration.scss'
import RegisterBusiness from './../assets/images/RegisterBusiness.png';
import registrationImg from './../assets/images/registrationImg.png'



function BusinessRegistration(){

    
    // useEffect(()=>{
    //     console.log(SelectCompanyInfoSuffix)
    //     console.log(SelectCompanyInfoStates)
    //     console.log(selectMailingAddressStates)
    //     console.log(SelectCompanyAddressStates)
    //     console.log(SelectMemberInfoStates)
    //     console.log(SelectRegisteredAgentAddressStates)
    //     console.log(SelectPaymentMetod)
    //     console.log(SelectPaymentStates)
        
    // })
    
    
    
    // Business Entity Registration
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    
    const [mailingAddressOpen, setMailingAddressOpen] = useState(true)
    useEffect(() =>{
        if (firstName && lastName && email && phone){
            setMailingAddressOpen(false);
        }
        else{
            setMailingAddressOpen(true);
        }
    }, [firstName,lastName,email,phone])
    


    // Mailing Address

    const [mailingStreetAddress, setMailingStreetAddress] = useState("")
    const [mailingCity, setMailingCity] = useState("")

    const [mailingAddressStates, setMailingAddressStates] = useState(["State" , "California", "New-york", "Texas"])
    const [selectMailingAddressStates, setSelectMailingAddressStates] = useState(mailingAddressStates[0])
    const MailingAddressStatesoptions = mailingAddressStates.map(MailingAddressStatesoptions => MailingAddressStatesoptions)
    function MailingAddressStatesChange(e){
        setSelectMailingAddressStates(mailingAddressStates[e.target.value]);
    }
    const [mailingAddressZip, setMailingAddressZip] = useState("")

    const [companyInfoOpen, setCompanyInfoOpen] = useState(true)
    useEffect(() =>{
        if (mailingStreetAddress && mailingCity && selectMailingAddressStates !== mailingAddressStates[0]  && mailingAddressZip){
            setCompanyInfoOpen(false);
        }
        else{
            setCompanyInfoOpen(true);
        }
    }, [mailingStreetAddress,mailingCity,mailingAddressZip,selectMailingAddressStates,mailingAddressStates])
    

    // Company Info

    const [companyInfochecked, setCompanyInfochecked] = useState('LLC');
    const [companyName, setCompanyName] = useState("")
    const [companyInfoSuffix, setCompanyInfoSuffix] = useState(["SUFFIX" , "California", "New-york", "Texas"])
    const [selectCompanyInfoSuffix, setSelectCompanyInfoSuffix] = useState("SUFFIX")
    const CompanyInfoSuffixOptions = companyInfoSuffix.map(CompanyInfoSuffixOptions => CompanyInfoSuffixOptions)
    function CompanyInfoSuffixChange(e){
        setSelectCompanyInfoSuffix(companyInfoSuffix[e.target.value])
    }

    const [companyInfoStates, setCompanyInfoStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [selectCompanyInfoStates, setSelectCompanyInfoStates] = useState(companyInfoStates[0])
    const CompanyInfoStatesoptions = companyInfoStates.map(CompanyInfoStatesoptions => CompanyInfoStatesoptions)
    function CompanyInfoStatesChange(e){
        setSelectCompanyInfoStates(companyInfoStates[e.target.value]);
    }
   
    const [CompanyAddressOpen, setCompanyAddressOpen] = useState(true)
    useEffect(() =>{
    
        if (companyName && (selectCompanyInfoSuffix !== companyInfoSuffix[0]) && (selectCompanyInfoStates !== companyInfoStates[0])){
            setCompanyAddressOpen(false);
        }
        else{
            setCompanyAddressOpen(true);
        }
      
    }, [companyName, companyInfoSuffix, selectCompanyInfoSuffix,selectCompanyInfoStates,companyInfoStates])
    


    // Company Address

    const [checked, setChecked] = useState(false)
    const handleClick = () => setChecked(!checked)
    const test1234 = useRef("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyCity, setCompanyCity] = useState("")
    const [companyAddressZip, setCompanyAddressZip] = useState("")
    const [companyAddressStates, setCompanyAddressStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [selectCompanyAddressStates, setSelectCompanyAddressStates] = useState(companyAddressStates[0])
    const CompanyAddressoptionsStates = companyAddressStates.map(CompanyAddressoptionsStates => CompanyAddressoptionsStates)
    function CompanyAddressChange(e){
        setSelectCompanyAddressStates(companyAddressStates[e.target.value]);
    }

    const [MemberInfoOpen, setMemberInfoOpen] = useState(true)
    useEffect(() =>{
        if (companyName && (selectCompanyInfoSuffix !== companyInfoSuffix[0]) && (selectCompanyInfoStates !== companyInfoStates[0])){
            setMemberInfoOpen(false);
        }
        else{
            setMemberInfoOpen(true);
        }
        if(checked){
            setCompanyAddress(mailingStreetAddress);
            setCompanyCity(mailingCity);
            console.log(selectMailingAddressStates);
            setSelectCompanyAddressStates(selectMailingAddressStates);
            setCompanyAddressZip(mailingAddressZip);
        }
        else{
            
                setCompanyAddress(companyAddress);
                setCompanyCity(companyCity);
                setSelectCompanyAddressStates(selectCompanyAddressStates);
                setCompanyAddressZip(companyAddressZip);
        }
    }, [checked, companyAddress, companyCity , companyAddressStates, selectCompanyAddressStates,companyName,mailingStreetAddress,mailingCity,mailingAddressZip,selectMailingAddressStates,mailingAddressStates])
    
    useEffect(() =>{
        if(!checked){
                setCompanyAddress("");
                setCompanyCity("");
                setSelectCompanyAddressStates("");
                setCompanyAddressZip("");
        }
    }, [checked])
    

    

    


    // Member Info
    const [MemberInfoClick, setMemberInfoClick] = useState(false)
    const MemberInfoHandleClick = () => setMemberInfoClick(!MemberInfoClick)
    // alert(MemberInfoClick)
    const [MemberFirstName, setMemberFirstName] = useState("")
    const [MemberLastName, setMemberLastName] = useState("")
    const [MemberStreetAddress, setMemberStreetAddress] = useState("")
    const [MemberCity, setMemberCity] = useState("")
    const [MemberInfoStates, setMemberInfoStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [SelectMemberInfoStates, setSelectMemberInfoStates] = useState(MemberInfoStates[0])
    const [MemberZip, setMemberZip] = useState("")
    const MemberInfooptionsStates = MemberInfoStates.map(MemberInfooptionsStates => MemberInfooptionsStates)
    function MemberInfoChange(e){
        setSelectMemberInfoStates(MemberInfoStates[e.target.value]);
    }
    
    const [RegisteredAgentOpen, setRegisteredAgentOpen] = useState(true)
    useEffect(() =>{
    
        if (MemberFirstName && MemberLastName && MemberStreetAddress && MemberCity && MemberInfoStates && MemberZip){
            setRegisteredAgentOpen(false);
        }
        else{
            setRegisteredAgentOpen(true);
        }
        
        if(MemberInfoClick){
            setMemberStreetAddress(mailingStreetAddress);
            setMemberCity(mailingCity);
            setSelectMemberInfoStates(selectMailingAddressStates);
            setMemberZip(mailingAddressZip);
        }
        else{
            
            setMemberStreetAddress(MemberStreetAddress);
            setMemberCity(MemberCity);
            setSelectMemberInfoStates(SelectMemberInfoStates);
            setMemberZip(MemberZip);
        }
      
    }, [MemberInfoClick,MemberFirstName,MemberLastName,MemberStreetAddress,MemberCity,MemberInfoStates,MemberZip])

    useEffect(() =>{
        if(!MemberInfoClick){
            setMemberStreetAddress("");
            setMemberCity("");
            setSelectMemberInfoStates("");
            setMemberZip("");
        }
    }, [MemberInfoClick])


    // Registered Agent Address
    const [registerAgent, setRegisterAgent] = useState('weAgent');
    const [AgentFirstName, setAgentFirstName] = useState("")
    const [AgentLastName, setAgentLastName] = useState("")
    const [AgentAddress, setAgentAddress] = useState("")
    const [AgentCity, setAgentCity] = useState("")
    const [RegisteredAgentAddressStates, setRegisteredAgentAddressStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [SelectRegisteredAgentAddressStates, setSelectRegisteredAgentAddressStates] = useState("")
    const RegisteredAgentAddressoptionsStates = RegisteredAgentAddressStates.map(RegisteredAgentAddressoptionsStates => RegisteredAgentAddressoptionsStates)
    function RegisteredAgentAddressChange(e){
        setSelectRegisteredAgentAddressStates(RegisteredAgentAddressStates[e.target.value]);
    }

    // Payment Info
    const [PaymentInfoChecked, setPaymentInfoChecked] = useState(false)
    const PaymentInfoCheckedClick = () => setPaymentInfoChecked(!PaymentInfoChecked)
    const [PaymentCardNumber, setPaymentCardNumber] = useState("")
    const [PaymentNameOnCard, setPaymentNameOnCard] = useState("")
    const [PaymentStreetAddressOnCard, setPaymentStreetAddressOnCard] = useState("")
    const [PaymentCity, setPaymentCity] = useState("")
    const [PaymentMetod, setPaymentMetod] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [SelectPaymentMetod, setSelectPaymentMetod] = useState("")
    const PaymentMetodOptionsStates = PaymentMetod.map(RegisteredAgentAddressoptionsStates => RegisteredAgentAddressoptionsStates)
    function RegisteredAgentAddressChange(e){
        setSelectPaymentMetod(PaymentMetod[e.target.value]);
    }

    const [PaymentStates, setPaymentStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [SelectPaymentStates, setSelectPaymentStates] = useState("")
    const PaymentOptionsStates = PaymentStates.map(PaymentoptionsStates => PaymentoptionsStates)
    function PaymentChange(e){
        setSelectPaymentStates(PaymentStates[e.target.value]);
    }



    function CreateBudiness(event){
        event.preventDefault(); 
        // alert('Company Address' + checked)
        // alert('Company Info' + CompanyInfochecked)
        // alert('Member Info' + MemberInfoClick)
        // alert('Registered Agent Address' + registerAgent)
        // alert(' Payment Info' + PaymentInfoChecked)
    }

    return(
        <div className='BusinessRegistration'>
            <form onSubmit={(e) => CreateBudiness(e)}>
                <div className='BusinessRegistration__banerR'>
                    <h1  className='BusinessRegistration__banerR__title wrapperR'>Business Entity Registration</h1>
                    <h3  className='BusinessRegistration__banerR__subTitle wrapperR'>Business Entity Registration</h3>
                    <img src={RegisterBusiness} className='banerR__img' alt='gegister-banner-img'/>
                    <div className='BusinessRegistration__banerR__imputs' />
                    <div className='BusinessRegistration__banerR__bg'>
                        <div className='wrapperR'>
                            <div className='BusinessRegistration__banerR__inputs'>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='First Name'  onChange={e => setFirstName(e.target.value)} value={firstName} />
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Last Name'  onChange={e => setLastName(e.target.value)} value={lastName}  />
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='email' placeholder='Email'  onChange={e => setEmail(e.target.value)} value={email} />
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='number' placeholder='Phone'  onChange={e => setPhone(e.target.value)} value={phone} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='BusinessRegistration__block'>
                    <div className='BusinessRegistration__block__bottom'>
                        <div className='BusinessRegistration__block__left'>
                            <h2 className='BusinessRegistration__blockTitle__left'>Mailing Address</h2>
                            <div className='BusinessRegistration__block__left__contain'>
                                <div className='BusinessRegistration__block__box'>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input className={!mailingAddressOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} type='text' placeholder='Street Address' onChange={e => setMailingStreetAddress(e.target.value)} value={mailingStreetAddress} disabled={mailingAddressOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className={!mailingAddressOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} type='text' placeholder='City' onChange={e => setMailingCity(e.target.value)} value={mailingCity} disabled={mailingAddressOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <select className={!mailingAddressOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} onChange={MailingAddressStatesChange} disabled={mailingAddressOpen}>
                                            {
                                                MailingAddressStatesoptions.map((address, key) => <option value={key}>{address}</option>)
                                            }
                                        </select>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className={!mailingAddressOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} type='text' placeholder='Zip' onChange={e => setMailingAddressZip(e.target.value)} value={mailingAddressZip} disabled={mailingAddressOpen}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='BusinessRegistration__block__right'>
                            <h2 className='BusinessRegistration__blockTitle__right'>Company Info</h2>
                            <div className='BusinessRegistration__block__right__contain'>
                                <div className='BusinessRegistration__block__box'>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className={!companyInfoOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} type='text' placeholder='Company Name' onChange={e => setCompanyName(e.target.value)} value={companyName} disabled={companyInfoOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <select  className={!companyInfoOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} onChange={CompanyInfoSuffixChange} disabled={companyInfoOpen}>
                                            {
                                                CompanyInfoSuffixOptions.map((address, key) => <option value={key}>{address}</option>)
                                            }
                                        </select>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <select  className={!companyInfoOpen ? 'BusinessRegistration__banerR__item' : 'BusinessRegistration__banerR__item disibled'} onChange={CompanyInfoStatesChange} disabled={companyInfoOpen}>
                                            {
                                                CompanyInfoStatesoptions.map((address, key) => <option value={key}>{address}</option>)
                                            }
                                        </select>
                                    </label>
                                    
                                    <div  className={companyInfoOpen ? 'BusinessRegistration__radios disibled' : 'BusinessRegistration__radios'}>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>searchInp</span>
                                            <input type='radio' checked={companyInfochecked === 'LLC'} name="CompanyInfo" onClick={() => setCompanyInfochecked('LLC')} disabled={companyInfoOpen}/>
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>LLC</span>
                                        </label>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>1111</span>
                                            <input type='radio' checked={companyInfochecked === 'S-CORP'} name="CompanyInfo" onClick={() => setCompanyInfochecked('S-CORP')} disabled={companyInfoOpen}/>
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>S-CORP</span>
                                        </label>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>searchInp</span>
                                            <input type='radio' checked={companyInfochecked === 'C-CORP'} name="CompanyInfo" onClick={() => setCompanyInfochecked('C-CORP')} disabled={companyInfoOpen} />
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>C-CORP</span>
                                        </label>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>searchInp</span>
                                            <input type='radio' checked={companyInfochecked === 'NON-PROFIT'} name="CompanyInfo" onClick={() => setCompanyInfochecked('NON-PROFIT')} disabled={companyInfoOpen}/>
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>NON-PROFIT</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='BusinessRegistration__block__solo'>
                    <div className='BusinessRegistration__block__solo__top'>
                        <h2 className='BusinessRegistration__blockTitle__right'>Company Address</h2>
                    </div>
                    <div className='BusinessRegistration__block__solo__bottom'>
                        <div className='BusinessRegistration__block__image'>
                            <img src={registrationImg} alt='registration-Img'/>
                        </div>
                        <div className='BusinessRegistration__block__right'>
                            <div className='BusinessRegistration__block__right__contain'>
                            <div className={CompanyAddressOpen ? 'BusinessRegistration__block__box disibled' : 'BusinessRegistration__block__box'}>
                                <div  className={'BusinessRegistration__radiosT'}>
                                    <label className='BusinessRegistration__radioL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input onClick={handleClick} checked={checked}  type='checkbox' name="CompanyAddress" disabled={CompanyAddressOpen}/>
                                        <span className='checkmarkR'></span>
                                        <span className='BusinessRegistration__radios__text'>Same as mailing address</span>
                                    </label>
                                </div>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input className='BusinessRegistration__banerR__item' type='text' placeholder='Company Street Address'  onChange={e => setCompanyAddress(e.target.value)} value={companyAddress}  disabled={CompanyAddressOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input className='BusinessRegistration__banerR__item' type='text' placeholder='City' onChange={e => setCompanyCity(e.target.value)} value={companyCity} disabled={CompanyAddressOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                   {
                                    !checked? 
                                    <select  className='BusinessRegistration__banerR__item' onChange={CompanyAddressChange} disabled={CompanyAddressOpen}> 
                                        {
                                            CompanyAddressoptionsStates.map((address, key) => <option value={key}>{address}</option>)
                                        }
                                    </select>
                                    :
                                    <select  className='BusinessRegistration__banerR__item' onChange={CompanyAddressChange} disabled={CompanyAddressOpen}> 
                                    <option value={selectCompanyAddressStates}>{selectCompanyAddressStates}</option>
                                    
                                </select>
                                    }
                                </label>
                                <label className='BusinessRegistration__banerR__itemL' >
                                    <span className='visually-hidden'>searchInp</span>
                                    <input   className='BusinessRegistration__banerR__item' type='text' placeholder='Zip' onChange={e => setCompanyAddressZip(e.target.value)} value={companyAddressZip} disabled={CompanyAddressOpen}/>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='BusinessRegistration__block'>
                    <div className='BusinessRegistration__block__bottom'>
                        <div className='BusinessRegistration__block__left'>
                            <h2 className='BusinessRegistration__blockTitle__left'>Member Info</h2>
                            <div className='BusinessRegistration__block__left__contain'>
                            <div className={MemberInfoOpen ? 'BusinessRegistration__block__box disibled' : 'BusinessRegistration__block__box'}>
                                <div  className='BusinessRegistration__radiosT'>
                                    <label className='BusinessRegistration__radioL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input onClick={MemberInfoHandleClick} checked={MemberInfoClick} type='checkbox' name="LLC" placeholder='Last Name'/>
                                        <span className='checkmarkR'></span>
                                        <span className='BusinessRegistration__radios__text'>Same as mailing address</span>
                                    </label>
                                </div>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='First Name' onChange={e => setMemberFirstName(e.target.value)} value={MemberFirstName} disabled={MemberInfoOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Last Name' onChange={e => setMemberLastName(e.target.value)} value={MemberLastName} disabled={MemberInfoOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Street Address' onChange={e => setMemberStreetAddress(e.target.value)} value={MemberStreetAddress} disabled={MemberInfoOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='City' onChange={e => setMemberCity(e.target.value)} value={MemberCity} disabled={MemberInfoOpen}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    {
                                    !MemberInfoClick ? 
                                    <select className='BusinessRegistration__banerR__item' onChange={MemberInfoChange} disabled={MemberInfoOpen}>
                                        {
                                            MemberInfooptionsStates.map((address, key) => <option value={key}>{address}</option>)
                                        }
                                    </select>
                                    :
                                    <select  className='BusinessRegistration__banerR__item' onChange={MemberInfoChange} disabled={MemberInfoOpen}>
                                        <option value={SelectMemberInfoStates}>{SelectMemberInfoStates}</option>
                                    </select> 
                                    }
                                </label>

    


                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input className='BusinessRegistration__banerR__item' type='text' placeholder='Zip' onChange={e => setMemberZip(e.target.value)} value={MemberZip} disabled={MemberInfoOpen}/>
                                </label>
                                <button className='Member-info__btn'>get data</button>
                            </div>
                            </div>
                        </div>
                        <div className='BusinessRegistration__block__right'>
                        <h2 className='BusinessRegistration__blockTitle__right'>Registered Agent Address</h2>
                            <div className='BusinessRegistration__block__right__contain'>
                                <div className={RegisteredAgentOpen ? 'BusinessRegistration__block__box disibled' : 'BusinessRegistration__block__box'}>
                                    <div  className='BusinessRegistration__radiosT'>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>searchInp</span>
                                            <input checked={registerAgent === 'weAgent'} onClick={() => setRegisterAgent('weAgent')} type='radio' name="AgentAddress" disabled={RegisteredAgentOpen}/>
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>use fast track ra address</span>
                                        </label>
                                        <label className='BusinessRegistration__radioL'>
                                            <span className='visually-hidden'>searchInp</span>
                                            <input  checked={registerAgent === 'yorAgent'} onClick={() => setRegisterAgent('yorAgent')} type='radio' name="AgentAddress" disabled={RegisteredAgentOpen}/>
                                            <span className='checkmarkR'></span>
                                            <span className='BusinessRegistration__radios__text'>use MY HOME ADDRESS</span>
                                        </label>
                                    </div>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className='BusinessRegistration__banerR__item' type='text' placeholder='First Name' onChange={e => setAgentFirstName(e.target.value)} value={AgentFirstName} disabled={RegisteredAgentOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Last Name' onChange={e => setAgentLastName(e.target.value)} value={AgentLastName} disabled={RegisteredAgentOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Street Address' onChange={e => setAgentAddress(e.target.value)} value={AgentAddress} disabled={RegisteredAgentOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className='BusinessRegistration__banerR__item' type='text' placeholder='City' onChange={e => setAgentCity(e.target.value)} value={AgentCity} disabled={RegisteredAgentOpen}/>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        {/* <input  className='BusinessRegistration__banerR__item' type='text' placeholder='State'/> */}
                                        <select className='BusinessRegistration__banerR__item' onChange={CompanyAddressChange} disabled={RegisteredAgentOpen}>
                                            {
                                                RegisteredAgentAddressoptionsStates.map((address, key) => <option value={key}>{address}</option>)
                                            }
                                        </select>
                                    </label>
                                    <label className='BusinessRegistration__banerR__itemL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Zip' disabled={RegisteredAgentOpen}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PackegesR/>
                <div className='BusinessRegistration__block'>
                    <div className='BusinessRegistration__block__bottom'>
                        <div className='BusinessRegistration__block__left'>
                            <h2 className='BusinessRegistration__blockTitle__left'>Cost</h2>
                            <div className='BusinessRegistration__block__left__contain'>
                                <div className='BusinessRegistration__block__Text_box'>
                                    <p className='BusinessRegistration__block__Text'>Package fee</p>
                                    <p className='BusinessRegistration__block__Text'>State fee</p>
                                    <p className='BusinessRegistration__block__Text'>Shipping ANd Handling</p>
                                </div>
                            </div>
                        </div>
                        <div className='BusinessRegistration__block__right'>
                            <h2 className='BusinessRegistration__blockTitle__right'>Payment Info</h2>
                            <div className='BusinessRegistration__block__right__contain'>
                            <div className='BusinessRegistration__block__box'>
                                <div  className='BusinessRegistration__radiosT'>
                                    <label className='BusinessRegistration__radioL'>
                                        <span className='visually-hidden'>searchInp</span>
                                        <input  onClick={PaymentInfoCheckedClick} checked={PaymentInfoChecked} type='checkbox'name="Payment Info"/>
                                        <span className='checkmarkR'></span>
                                        <span className='BusinessRegistration__radios__text'>Payment Info</span>
                                    </label>
                                </div>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <select className='BusinessRegistration__banerR__item' onChange={RegisteredAgentAddressChange}>
                                        {
                                            PaymentMetodOptionsStates.map((address, key) => <option value={key}>{address}</option>)
                                        }
                                    </select>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Card Number' onChange={e => setPaymentCardNumber(e.target.value)} value={PaymentCardNumber}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Name on Card' onChange={e => setPaymentNameOnCard(e.target.value)} value={PaymentNameOnCard}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='' onChange={e => setPaymentStreetAddressOnCard(e.target.value)} value={PaymentStreetAddressOnCard}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Street Address on Card' onChange={e => setPaymentCity(e.target.value)} value={PaymentCity}/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='City'/>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <select className='BusinessRegistration__banerR__item' onChange={PaymentChange}>
                                        {
                                            PaymentOptionsStates.map((address, key) => <option value={key}>{address}</option>)
                                        }
                                    </select>
                                </label>
                                <label className='BusinessRegistration__banerR__itemL'>
                                    <span className='visually-hidden'>searchInp</span>
                                    <input  className='BusinessRegistration__banerR__item' type='text' placeholder='Zip'/>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <button type='submit' className='BusinessRegistration__banerR_Confirm'>Confirm</button>
            </form>
            <Footer/>
        </div>
    )
}

export default BusinessRegistration