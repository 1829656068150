import React from 'react'
import './../scss/packeges.scss'
import { ReactComponent as Path2 } from './../assets/images/PathCircle.svg';

function Packeges(){
    return(
        <div className='Packeges'>
            <div  className='Packeges__Cards'>
                <div className='Packeges__item'>
                    <h2  className='Packeges__Title1'>
                      <label className="containerR">
                        <input type="radio" name="radio" />
                        <span className="checkmarkR"></span>
                      </label>
                      Essential
                    </h2>
                    <p className='Packeges__price'>139$</p>
                    <p className='Packeges__SubTitle1'>Corporate Filing</p>
                    <p className='Packeges__text'>Articles of Incorporation</p>
                    <p className='Packeges__text'>EIN/Federal Tax ID Number</p>
                    <p className='Packeges__text__list'>Registered Agent Service(129$ annually)</p>
                    <p className='Packeges__text__list'>Banking Resoultion Document</p>
                    <p className='Packeges__text__list'>Operation Agreement Document</p>
                </div>
                <div className='Packeges__item Packeges__center'>
                    <h2  className='Packeges__Title2'>
                        <label className="containerR">
                          <input type="radio" name="radio"/>
                          <span className="checkmarkR"></span>
                        </label>
                    Entrepreneur</h2>
                    <p className='Packeges__price'>249$</p>
                    <p className='Packeges__SubTitle2'>Corporate Filing</p>
                    <p className='Packeges__text'>Articles of Incorporation</p>
                    <p className='Packeges__text'>EIN/Federal Tax ID Number</p>
                    <p className='Packeges__text'>Corporate Kit</p>
                    <p className='Packeges__text__list'>Registered Agent Service(Included)</p>
                    <p className='Packeges__text__list'>Banking Resolution Document</p>
                    <p className='Packeges__text__list'>Operating Agreement Document</p>
                    <p className='Packeges__text__list'>Automatic Annual Renewal (State fee not included)</p>
                </div>
                <div className='Packeges__item'>
                    <h2  className='Packeges__Title3'>
                        <label className="containerR">
                          <input type="radio" name="radio"/>
                          <span className="checkmarkR"></span>
                        </label>
                    Enterprise</h2>
                    <p className='Packeges__price'>529$</p>
                    <p className='Packeges__SubTitle3'>Corporate Filing</p>
                    <p className='Packeges__text'>Articles of Incorporation</p>
                    <p className='Packeges__text'>EIN/Federal Tax ID Number</p>
                    <p className='Packeges__text'>Corporate Kit</p>

                    <p className='Packeges__text__list'>Registered Agent Service(Included)</p>
                    <p className='Packeges__text__list'>Banking Resolution Document</p>
                    <p className='Packeges__text__list'>Operating Agreement Document</p>
                    <p className='Packeges__text__list'>Automatic Annual Renewal (State fee not included)</p>
                    <p className='Packeges__text__list'>Operating agreement</p>
                    <p className='Packeges__text__list'>Free Dissolution Service</p>
                    <p className='Packeges__text__list'>Full Custom Website</p>
                    <p className='Packeges__text__list'>5 smail accounts</p>
                    <p className='Packeges__text__list'>q year free hosting</p>
                </div>
            </div>
            <Path2 className='test1'/>
            <div  className='Packeges__BG'>

            </div>
        </div>
    )
}

export default Packeges