import React from 'react'
import './../scss/footer.scss'
import { Link } from 'react-router-dom';
import  Face from './../assets/images/facebook.png';
import  Linkedin from './../assets/images/linkedin.png';
import  Twitter from './../assets/images/twitter.png';
import  Instagram from './../assets/images/instagram.png';
// import { ReactComponent as Twitter } from './../assets/images/twitter.svg';
// import { ReactComponent as Instagram } from './../assets/images/instagram.svg';
// import { ReactComponent as Linkedin } from './../assets/images/linkedin.svg';



function Footer(){
    return(

        <footer className='footer'>
            <div className='wrapper footer__container'>
                <div className='footer__Entites'>
                    <h2 className='footer__title'>BUSSINES ENTITES</h2>
                    <Link to='/LLC_corp' className='footer__Link'>LLC</Link>
                    <Link to='/s_corp' className='footer__Link'>s-corp</Link>
                    <Link to='/c_corp' className='footer__Link'>c-corp</Link>
                    <Link to='/no_Profit' className='footer__Link'>Non-profit</Link>
                </div>
                <div className='footer__Services'>
                    <h2 className='footer__title'>SERVICES</h2>
                    {/* <p className='footer__text'>State Filing</p>
                    <p className='footer__text'>Name change amendment</p>
                    <p className='footer__text'>Address change amendment</p> */}
                    <p className='footer__text'> Dba/fictious business name</p>
                    <p className='footer__text'>Ein/tax id number</p>
                    <p className='footer__text'>501c3 status</p>
                    {/* <p className='footer__text'>Member change amendment</p>
                    <p className='footer__text'>Foreign entity registration</p>
                    <p className='footer__text'>DBA/Fictitious business name</p> */}
                </div>
                {/* <div className='footer__Entites__Links'>
                    <p className='footer__text'>Annual report (annual reneval)</p>
                    <p className='footer__text'>Certificate of good standing</p>
                    <p className='footer__text'>Corporate kit</p>
                    <p className='footer__text footer__Entites_block'>IRS Fillings</p>
                    <p className='footer__text'>EIN/TAX ID Number</p>
                    <p className='footer__text'>S-Corp Election</p>
                    <p className='footer__text'>Registered agent service</p>   
                </div> */}
                <div className='footer__FollowUs'>
                    <h2  className='footer__title'>Follow Us</h2>
                    <div  className='footer__Items'>
                        <a target='blank' href='https://www.youtube.com/'><img src={Face}  className='footer__Item' alt='facebookIcon'/></a>
                        <a target='blank' href='https://www.youtube.com/'><img src={Linkedin}  className='footer__Item' alt='LinkedinIcon'/></a>
                        <a target='blank' href='https://www.youtube.com/'><img src={Twitter}  className='footer__Item' alt='TwitterIcon'/></a>
                        <a target='blank' href='https://www.youtube.com/'><img src={Instagram}  className='footer__Item' alt='InstagramIcon'/></a>
                        {/* <a target='blank' href='https://www.youtube.com/'><Linkedin  className='footer__Item'/></a>
                        <a target='blank' href='https://www.youtube.com/'><Twitter  className='footer__Item'/></a>
                        <a target='blank' href='https://www.youtube.com/'><Instagram  className='footer__Item'/></a> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer