import React from 'react'
import CorpVSNoProfit from '../components/corpVSNoProfit'
import Footer from '../components/Footer'
import Frequently from '../components/Frequently'
import IntroToNoProfit from '../components/introToNoProfit'
import SCopeForm from '../components/SCopeForm'
import Packages from '../components/packeges'
import GetStarted from '../components/getStarted'
import { ReactComponent as Path1 } from './../assets/images/noProfit.svg';
import { Link } from 'react-scroll';
import './../scss/S_Cope.scss'

function NoProfite(){
    return(
        <div className='NoProfite'>
            <div className='baner'>
                <div className='baner__content'>
                    <h2 className='baner__content__title'>Ready to form your Non Profit?</h2>
                    <h3 className='baner__content__subtitle'>Three bullet points:</h3>
                    <p  className='baner__content_text'>Easy to bring on investors</p>
                    <p  className='baner__content_text'>Unlimited shareholders</p>
                    <p  className='baner__content_text'>Unlimited company lifespan</p>
                    {/* <p  className='baner__content_text'>write off expenses</p> */}
                    <div  className='baner__content__block'>
                        <GetStarted/>
                        <Link  className='baner__content__read'  to='packeges' smooth={true} duration={1000} offset={-150}>Read more below</Link>
                    </div>
                </div>
                <Path1 className='baner__img'/>
                <div id='packeges'/>
            </div>
            <Packages/>
            <IntroToNoProfit/>
            <CorpVSNoProfit/>
            <Frequently/>
            <SCopeForm/>
            <Footer/>
        </div>
    )
}

export default NoProfite