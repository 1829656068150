import React from 'react'
import { Link } from 'react-router-dom';


function GetStarted(){
    return(
        <Link  className='webBTN' to='/businessRegistration'>Get Started</Link>
    )
}

export default GetStarted