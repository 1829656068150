import React from 'react'
import './../scss/frequently.scss'

function Frequently(){
    return(
        <div className='frequently'>
            <h2 className='title__Text'>Frequently Asked Questions</h2>
            <span className='title__line'/>

            <div className='frequently__DropDown'>
                <details className='frequently__details'>
                    <summary  className='frequently__summary'>How do I become an S Corp?</summary>
                    <p  className='frequently__text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remai.</p>
                </details>
                <details className='frequently__details'>
                    <summary  className='frequently__summary'>How long do I have to make a subchapter S Election?</summary>
                    <p  className='frequently__text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remai.</p>
                </details>
                <details className='frequently__details'>
                    <summary  className='frequently__summary'>Can I always go from S-corp back to C-Corp?</summary>
                    <p  className='frequently__text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remai.</p>
                </details>
            </div>
        </div>
    )
}

export default Frequently