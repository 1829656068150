import React from 'react'
import './App.css';
import ScrollToTop from './scrollToTop/ScrollToTop';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import Home from './pages/Home'
import NoMatch from './pages/NoMatch';
import BusinessRegistration from './pages/BusinesRegistration';
import Navbar from './components/Navbar';
import SCope from './pages/S_cope';
import CCope from './pages/C_cope';
import LLCCope from './pages/LLC_cope';
import NoProfit from './pages/NoProfit'
import Ein from './pages/EIN';


function App() {
  return (

    <BrowserRouter>
      <div className='page' id='page'>
          <Navbar/>
          <div id='routerContent'>
              <ScrollToTop />
              <Switch>
                  <Route path='/businessRegistration' render={() => <BusinessRegistration/>}></Route>
                  <Route path='/s_corp' render={() => <SCope/>}></Route>
                  <Route path='/c_corp' render={() => <CCope/>}></Route>
                  <Route path='/LLC_corp' render={() => <LLCCope/>}></Route>
                  <Route path='/no_Profit' render={() => <NoProfit/>}></Route>
                  <Route path='/ein' render={() => <Ein/>}></Route>
                  <Route exact path='/' render={() => <Home/>}></Route>
                  <Route component={NoMatch}/>
              </Switch>
          </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
