import React from 'react'
import CorpVS from '../components/corpVS'
import Footer from '../components/Footer'
import Frequently from '../components/Frequently'
import IntroTo from '../components/introTo'
import SCopeForm from '../components/SCopeForm'
import Packages from '../components/packegesCorp'
import GetStarted from '../components/getStarted'
import { ReactComponent as Path1 } from './../assets/images/SCorp.svg';
import { Link } from 'react-scroll';
import './../scss/S_Cope.scss'

function S_Cope(){
    return(
        <div className='SCope'>
            <div className='baner'>
                <div className='baner__content'>
                    <h2 className='baner__content__title'>Ready to form your S-CORP?</h2>
                    <h3 className='baner__content__subtitle'>Three bullet points:</h3>
                    <p  className='baner__content_text'>Pass through entity</p>
                    <p  className='baner__content_text'>Great for partnerships</p>
                    <p  className='baner__content_text'>Can issue shares</p>
                    <div  className='baner__content__block'>
                        <GetStarted/>
                        <Link  className='baner__content__read' true='true'  to='packeges' smooth={true} duration={1000} offset={-150}>Read more below</Link>
                    </div>
                </div>
                <Path1 className='baner__img'/>
                <div id='packeges'/>
            </div>
            <Packages/>
            <IntroTo/>
            <CorpVS/>
            <Frequently/>
            <SCopeForm/>
            <Footer/>
        </div>
    )
}

export default S_Cope