import React from 'react'
import './../scss/introTo.scss'
// import './../scss/introTo.scss'

function IntroTo(){
    return(
        <div className='IntroTo'>
            <details className='IntroTo__details'>
                <summary  className='IntroTo__summary'>Intro to S -Corp</summary>
                <p  className='IntroTo__text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remai.</p>
            </details>
            <details className='IntroTo__details'>
                <summary  className='IntroTo__summary'>When is it beneficial to choose S-Corp?</summary>
                <p  className='IntroTo__text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remai.</p>
            </details>
        </div>
    )
}

export default IntroTo