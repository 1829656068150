import React from 'react'
import './../scss/servicesSlider.scss'
import { Link } from 'react-router-dom';
import card1 from './../assets/images/card1.png'
import card2 from './../assets/images/card2.png'
import card3 from './../assets/images/card3.png'


import OWLcorusel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'



const options = {
    responsive: {
        0: {
            items: 1,
        },
        550: {
            items: 2,
            margin: 11
        },
        850: {
            items: 3,
            margin: 21
        },
        1150: {
            items: 4,
            margin: 41
        }
    },
  };

function ServicesSlider(){
    return(
        <div className='servicesSlider'>
            <OWLcorusel  items='4' autoplay  autoplayHoverPause  loop responsive={options.responsive} margin={41}>
                <div  className='servicesSliderItem'>
                    <img src={card1}  className='servicesSliderItem__img' alt='servicesImg'/>
                    <div className='servicesSliderItem__text'>
                        <h3 className='servicesSliderItem__title'>Form an LLC</h3>
                        <p className='servicesSliderItem__description'>Jump start your business with the most convenient and flexible entity structure!</p>
                        <Link to='/LLC_corp'  className='Learn_More__link'>{'Learn More >>'}</Link>
                    </div>
                </div>
                <div  className='servicesSliderItem'>
                    <img src={card2}  className='servicesSliderItem__img' alt='servicesImg'/>
                    <div className='servicesSliderItem__text'>
                        <h3 className='servicesSliderItem__title'>Form an S-CORP</h3>
                        <p className='servicesSliderItem__description'>Want corporation benefits without paying corporate taxes? Launch your S-Corp today!</p>
                        <Link to='/s_corp'  className='Learn_More__link'>{'Learn More >>'}</Link>
                    </div>
                </div>
                <div  className='servicesSliderItem'>
                    <img src={card3}  className='servicesSliderItem__img' alt='servicesImg'/>
                    <div className='servicesSliderItem__text'>
                        <h3 className='servicesSliderItem__title'>Form a C-CORP</h3>
                        <p className='servicesSliderItem__description'>Plan on going public someday? Take the first step to launching your national corporation!</p>
                        <Link to='/c_corp'  className='Learn_More__link'>{'Learn More >>'}</Link>
                    </div>
                </div>
                <div  className='servicesSliderItem'>
                    <img src={card2}  className='servicesSliderItem__img' alt='servicesImg'/>
                    <div className='servicesSliderItem__text'>
                        <h3 className='servicesSliderItem__title'>501c3 Filing</h3>
                        <p className='servicesSliderItem__description'>Obtain tax exempt status! Your donors can write off their donations!</p>
                        <Link to='about'  className='Learn_More__link'>{'Learn More >>'}</Link>
                    </div>
                </div>
           
            </OWLcorusel>
        </div>
    )
}

export default ServicesSlider