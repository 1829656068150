import React from 'react'
import './../scss/corpVS.scss'
import g1 from './../assets/images/12.png'

function CorpVS(){
    return(
        <div className='CorpVS'>
            <h2  className='CorpVS__title__Text'>S-CORP</h2>
            <span  className='title__line'/>
            <div className='CorpVS__title'>
                <h2 className='CorpVS__titleAdv'><img src={g1} className='vsIcon' alt='good'/>Advantages</h2>
                <h2 className='CorpVS__titleVs'>VS</h2>
                <h2 className='CorpVS__titleDis'><img src={g1} className='vsIcon' alt='bad'/>Disadvantages</h2>
            </div>
            <div  className='CorpVS__block'>
                <h2 className='CorpVS__titleAdv CorpVS__title-mobile'>Advantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Limited Liability</h3>
                        <p  className='CorpVS__block__description'>Creditors can not go after business owners’ personal assets to pay off business debts.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Pass through Entity</h3>
                        <p  className='CorpVS__block__description'>There is no corporate tax for an S-Corp, meaning there is one layer of taxation, usually self employment tax, on the profits paid to its shareholders. This gives shareholders the advantage of holding profits in the company to offset taxation.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Transfer of ownership</h3>
                        <p  className='CorpVS__block__description'>Transferring ownership of an S-Corp is quite easy, relative to that of an LLC. You will not have to terminate the company nor will there be severe tax consequences in the process. Transfer of ownership will not cause complexity in the accounting or require adjustments to property owned by the corporation. If owners leave the company, the company stays alive. Unlike an LLC where if an owner leaves or dies, the company dies with them.</p>
                    </div>
                    <div className='CorpVS__block__item  advantages_item'>
                        <h3  className='CorpVS__block__title'>Tax favorable characterization of income</h3>
                        <p  className='CorpVS__block__description'>Shareholders can reduce self-employment tax by simply offsetting the profits off the company to stay with the company by taking salaries as employees. They also have the option to take tax free dividends. Meanwhile the owner is writing off business expenses and wages from the company profits.</p>
                    </div>
                </div>
                <h2 className='CorpVS__titleVs CorpVS__title-mobile '>VS</h2>
                <h2 className='CorpVS__titleDis CorpVS__title-mobile'>Disadvantages</h2>
                <div className='CorpVS__block__column'>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Ownership restrictions</h3>
                        <p  className='CorpVS__block__description'>S-Corps can only have 100 shareholders or less. All shareholders must be individuals, all must be U.S Citizens, and can not be corporate entities. Additionally, S-corps only offer one class of stock, unlike the C-Corp, which limits the kind of investors you’ll bring on.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>More IRS paperwork</h3>
                        <p  className='CorpVS__block__description'>With an S-Corp, you have flexibility in the characterization of your income. You can choose to present your income as wages or dividends, but this could lead to mischaracterization and could lead to the IRS asking you to recharacterize your income, thus leading to higher taxes.</p>
                    </div>
                    <div className='CorpVS__block__item disadvantages_item'>
                        <h3  className='CorpVS__block__title'>Tax obligation</h3>
                        <p  className='CorpVS__block__description'>Corporations are required by the IRS to follow the calendar year as the tax year. Other entity types have the freedom to choose any tax year type.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorpVS