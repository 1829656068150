import React from 'react'
import { Link } from 'react-router-dom';
import './../scss/s-corp_c-corp.scss'
// import { ReactComponent as Icon1 } from './../assets/images/business.svg';
// import { ReactComponent as Icon2 } from './../assets/images/bar-chart.svg';
// import { ReactComponent as Icon3 } from './../assets/images/meeting.svg';

import business from './../assets/images/business.png'
import barChart from './../assets/images/bar-chart.png'
import meeting from './../assets/images/meeting.png'

function Corp(){
    return(
        <div className='corp'>
            <div className='corp-title_block'>
                <h2 className='title__Text'>S-CORP VS. C-CORP VS. LLC</h2>
                <p className='corp-subTitle'>What is the difference?</p>
                <span  className='title__line'/>
            </div>
            <div  className='corp__card__block wrapper'>
                <div  className='corp__card card1'>
                    <img src={business} alt='businessIcon' className='corp__card_img'/>
                    <h2 className='corp__card__title'>S-CORP</h2>
                    <p className='corp__card__text'>Pass through entity (only taxed once), limited shareholders, unlimited lifespan</p>
                    <Link to='/s_corp' className='corp__card__BTN'>Read More</Link>
                </div>
                <div  className='corp__card card2'>
                    <img src={barChart} alt='barChartIcon' className='corp__card_img'/>
                    <h2 className='corp__card__title'>C-CORP</h2>
                    <p className='corp__card__text'>Pass through entity (only taxed once), limited shareholders, unlimited lifespan</p>
                    <Link to='/c_corp' className='corp__card__BTN'>Read More</Link>
                </div>
                <div  className='corp__card card3'>
                    <img src={meeting} alt='meetingIcon' className='corp__card_img'/>
                    <h2 className='corp__card__title'>LLC</h2>
                    <p className='corp__card__text'>Pass through entity (only taxed once), limited shareholders, unlimited lifespan</p>
                    <Link to='/LLC_corp' className='corp__card__BTN'>Read More</Link>
                </div>
            </div>
        </div>
    )
}

export default Corp