import React from 'react'
import CorpVSLLC from '../components/corpVSLLC'
import Footer from '../components/Footer'
import Frequently from '../components/Frequently'
import IntroToLLC from '../components/introToLLC'
import SCopeForm from '../components/SCopeForm'
import Packages from '../components/packegesCorp'
import GetStarted from '../components/getStarted'
import { ReactComponent as Path1 } from './../assets/images/LLC.svg';
import { Link } from 'react-scroll';
import './../scss/S_Cope.scss'

function LLC_Cope(){
    return(
        <div className='LLC_Cope'>
            <div className='baner'>
                <div className='baner__content'>
                    <h2 className='baner__content__title'>Ready to form your LLC?</h2>
                    <h3 className='baner__content__subtitle'>Three bullet points:</h3>
                    <p  className='baner__content_text'>Protect Personal Assets</p>
                    <p  className='baner__content_text'>Protect your business Name</p>
                    <p  className='baner__content_text'>within your state</p>
                    <p  className='baner__content_text'>write off expenses</p>
                    <div  className='baner__content__block'>
                        <GetStarted/>
                        <Link  className='baner__content__read' true='true'  to='packeges' smooth={true} duration={1000} offset={-150}>Read more below</Link>
                    </div>
                </div>
                <Path1 className='baner__img'/>
                <div id='packeges'/>
            </div>
            <Packages/>
            <IntroToLLC/>
            <CorpVSLLC/>
            <Frequently/>
            <SCopeForm/>
            <Footer/>
        </div>
    )
}

export default LLC_Cope