import React, {useState} from 'react'
import './../scss/home.scss'
import { Link } from 'react-router-dom';
import Scorp from '../components/s-corp_c-corp'
import Footer from '../components/Footer';
import Services from '../components/popularServices';
import Incorporate from '../components/incorporate';
import Packeges from '../components/packeges';
import GetStarted from '../components/getStarted'
import { ReactComponent as Path1 } from './../assets/images/Home.svg';
// import LoginModal from '../components/loginModal'

function Home(){

    const [sevicesStates, setSevicesStates] = useState(["Dba/fictious business name" , "Ein/tax id number", "501c3 status"])
    const [selectSevicesStates, setSelectSevicesStates] = useState("")
    const sevicesoptionsStates = sevicesStates.map(sevicesoptionsStates => sevicesoptionsStates)
    function sevicesChange(e){
        setSelectSevicesStates(sevicesStates[e.target.value]);
    }

    const [companyAddressStates, setCompanyAddressStates] = useState(["State of Incorporation" , "California", "New-york", "Texas"])
    const [selectCompanyAddressStates, setSelectCompanyAddressStates] = useState("")
    const CompanyAddressoptionsStates = companyAddressStates.map(CompanyAddressoptionsStates => CompanyAddressoptionsStates)
    function CompanyAddressChange(e){
        setSelectCompanyAddressStates(companyAddressStates[e.target.value]);
    }

    return(
        <div className='home'>         
            <div className='homeBaner'>
                <div className='homeBaner__content'>
                    <h2 className='homeBaner__content__title'>Ready to build your dream business?</h2>
                    <p  className='homeBaner__content_text'>Form the best entity for your business with today!</p>
                    <div  className='homeBaner__content__block'>
                        <select  className='homeBaner__content__select' onChange={sevicesChange}> 
                            {
                                 sevicesoptionsStates.map((address, key) => <option value={key}>{address}</option>)
                            }
    	                </select>
                        <select  className='homeBaner__content__select' onChange={CompanyAddressChange}> 
                            {
                                 CompanyAddressoptionsStates.map((address, key) => <option value={key}>{address}</option>)
                            }
    	                </select>
                    </div>
                    <div  className='homeBaner__content__block'>
                        <GetStarted/>
                        <h3  className='homeBaner__OR'>OR</h3>
                        <input  className='homeBaner__seacrchINP' placeholder='Free Name Search'/>
                    </div>
                </div>
                <div className='homeBaner__Learn_more'>
                    <p  className='homeBaner__Learn_more__title'>LEARN MORE</p>
                    <div className='homeBaner__Learn_more__links'>
                        <Link to='/LLC_corp' className='homeBaner__Learn_more__link'>FORM LLC</Link>
                        <Link to='/s_corp' className='homeBaner__Learn_more__link'>FORM S-CORP</Link>
                        <Link to='/c_corp' className='homeBaner__Learn_more__link'>FORM C-CORP</Link>
                        <Link to='/no_Profit' className='homeBaner__Learn_more__link'>FORM NON-PROFIT</Link>
                        <Link to='/' className='homeBaner__Learn_more__link'>DBA/FICTICIOUS BUSINESS NAME</Link>
                        <Link to='/' className='homeBaner__Learn_more__link'>501C3 FILING</Link>
                    </div>
                </div>
                <Path1 className='baner__img'/>
            </div>
            <Packeges/>
            <Services/>
            <Scorp/>
            <Incorporate/>
            <Footer />
        </div>
    )
}

export default Home