import React, { useState } from 'react';
import './../scss/navbar.scss';
import { Link } from 'react-router-dom';
import BusinessE from './navbarPopaps/businessE';
import AllServices from './navbarPopaps/allServices';
import Faq from './navbarPopaps/faq';
import FaqM from './navbarPopaps/faqM';
import AllServicesM from './navbarPopaps/allServicesM';
import BusinessEM from './navbarPopaps/businessEM';
import SearchInput from './searchInput';
import LoginModal from './../components/loginModal'

function Navbar() {
  const [hamburgerActiv, setHamburgerActiv] = useState(false);
  const [LoginOpenActiv, setLoginOpenActiv] = useState(false);
  const [RegistrationOpenActiv, setRegistrationOpenActiv] = useState(false);
  const hamburgerOpen = () => {
    setHamburgerActiv(hamburgerActiv => !hamburgerActiv);
  };
 

  function loginOpen(){
    setRegistrationOpenActiv(false)
  // alert('loginopen')
    setLoginOpenActiv(LoginOpenActiv => !LoginOpenActiv)
  }
  function RegistrationOpen(){
    // alert('Registeropen')
    setLoginOpenActiv(false)
    setRegistrationOpenActiv(RegistrationOpenActiv => !RegistrationOpenActiv)
  }


  return (
    <>
      <section className='menu'>
        <div className='menu-container'>
          <Link to='/' className='menu__logo'>
            <span>Fast</span>Track
          </Link>
          <div className='menu-grup'>
            <SearchInput/>
            <div className='menu__navigation'>
              <BusinessE/>
              <AllServices/>
              <Faq/>
            </div>
            <div className='menu__dashboard'>
              <a className='menu__Tel menu__Tel__dasctop' href='tel:(734)4509844'>(734)-450-9844</a>
              <p className='menu__login' onClick={loginOpen}>Login</p>
              <button  className='webBTN incorporateBtn'>Incorporate Now</button>
            </div>
            <div className='hamburger-mb-menu' onClick={hamburgerOpen}>
              <span
                className={!hamburgerActiv ? 'hamburger1' : 'hamburger11'}
              ></span>
              <span
                className={!hamburgerActiv ? 'hamburger2' : 'hamburger22'}
              ></span>
              <span
                className={!hamburgerActiv ? 'hamburger3' : 'hamburger33'}
              ></span>
            </div>
          </div>

          <div className={hamburgerActiv ? 'menu-blok-active' : 'menu-blok'} onClick={hamburgerOpen}>
            <div className='menu-blok__container' onClick={(e) => { e.stopPropagation(); }}>
              <ul className='menu-blok__navigation'>
                <button  className='webBTN menu-blok__margin'>Incorporate Now</button>
                <BusinessEM hamburgerOpen={hamburgerOpen}/>
                <AllServicesM  hamburgerOpen={hamburgerOpen}/>
                <FaqM  hamburgerOpen={hamburgerOpen}/>
                <a className='menu__Tel' href='tel:(734)4509844'>(734)-450-9844</a>
              </ul>
            </div>
          </div>
          <LoginModal loginOpen={loginOpen}  LoginOpenActiv={LoginOpenActiv} RegistrationOpen={ RegistrationOpen} />
        </div>
      </section>
    </>
  );
}

export default Navbar;
